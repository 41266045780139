<template>
  <div>
    <v-tabs
      :value="currentStepPath"
      background-color="cassiopee-purple"
      slider-color="cassiopee-purple"
      grow
      height="134px"
      active-class="active-custom-class"
      @change="setCurrentStepPath"
    >
      <v-tab 
        v-for="step in horizontalSteps" 
        :key="step.id"
        :to="step.path"
        class="d-flex flex-column form-stepper-tab"
      >
        <base-form-stepper
          :icon="step.icon"
          :grade="step.item"
          :name="step.name"
          :total-grade="horizontalSteps.length"
        />
        <form-progress-bar 
          :value="Math.round(dashboard[`${step.backEndName}`].percentage * 100)"
          class="mt-3" 
        />
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import BaseFormStepper from "./BaseFormStepper.vue"
import FormProgressBar from "./FormProgressBar.vue"
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: "FormStepperGlobal",
  components: { 
    BaseFormStepper,
    FormProgressBar
  },
  props: {
    progressValue: {
      type: String,
      default: "48"
    }
  },
  computed: {
    ...mapState('stepper', ['horizontalSteps', 'currentStepPath']),
    ...mapState('form', ['dashboard']),
  },
  created() {
    this.fetchDashboard();
  },
  methods: {
    ...mapMutations('stepper', ['setCurrentStepPath']),
    ...mapActions('form', [
    'fetchDashboard',
    ]),
  },
}
</script>

<style lang="scss">
@import '@/styles/variables';

.form-stepper-tab {
  opacity: 0.33;
}
.active-custom-class {
  background: map-deep-get($colors, 'cassiopee-purple', 'darken-1');
  opacity: 1 !important;
  box-shadow: 0px 1px 8px rgba(14, 14, 14, 0.5);
}

</style>
