<template>
  <div class="d-flex flex-column form-progress">
    <v-progress-linear
      color="cassiopee-yellow"
      background-color="cassiopee-purple darken-1"
      rounded
      height="8"
      :value="value"
    />
    <span class="mt-1 cassiopee-yellow--text text-subtitle-1"> 
      {{ value }}% 
    </span>
  </div>
</template>

<script>
export default {
  name: "FormProgressBar",
  props: {
    value: {
      type: Number,
      required: true,
    }
  }
}
</script>

<style lang="scss">
.form-progress {
  width: 200px;

  .v-progress-linear__determinate {
    border-radius: 8px !important;
  }
}
</style>