<template>
  <v-row 
    no-gutters 
    class="form-footer"
  >
    <v-col 
      cols="2" 
      class="my-auto"
    >
      <base-form-stepper 
        :icon="currentStep.icon | iconColor"
        :name="currentStep.name"
        :grade="currentStep.item"
        :total-grade="horizontalSteps.length"
        :step-footer="true"
        class="ml-16"
      />
    </v-col>
    <v-col 
      cols="3" 
      class="my-auto"
    >
      <span class="d-flex justify-center cassiopee-purple--text form-footer__legend">
        Champs indispensables *
      </span>
    </v-col>
    <v-col 
      cols="7"
      class="my-auto"
    >
      <div class="d-flex justify-end mr-16">
        <form-button-save @click="updateRouterSave" />
        <form-button-next-step 
          :full-color="true"
          @click="updateRouter(nextView)"
        />
        <form-button-results />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FormButtonSave from "./FormButtonSave.vue"
import FormButtonNextStep from "./FormButtonNextStep.vue"
import FormButtonResults from "./FormButtonResults.vue"
import BaseFormStepper from "./BaseFormStepper.vue"
import { mapGetters, mapState } from 'vuex'

export default {
  name: "TheFormFooter",
  filters: {
    iconColor: function(value) {
     return value.replace('White', 'Purple')
    }
  },
  components: { 
    FormButtonSave,
    FormButtonNextStep,
    FormButtonResults,
    BaseFormStepper
  },
  computed: {
    ...mapGetters('stepper', ['currentStep', 'nextView']),
    ...mapState('stepper', ['horizontalSteps']),
  },
  methods: {
    updateRouter(val){
      this.$router.push(val);
    },
    updateRouterSave() {
      this.$router.push('/dashboard')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-footer {
  height: 131px;
  border-top: 1px solid map-deep-get($colors, 'cassiopee-grey', 'lighten-2');

  &__legend {
    font-size: 17px;
  }
}
</style>