<template>
  <div class="text-center">
    <v-btn
      v-if="fullColor"
      class="ma-2 form-button-next"
      color="cassiopee-purple"
      height="50px"
      width="188px"
      @click="emitClick"
    >
      <span class="white--text text-button">Suivant</span>
      <v-icon 
        x-small 
        right
        color="white"
      >
        $arrowRight
      </v-icon>
    </v-btn>

    <v-btn
      v-else
      class="ma-2 form-button-next"
      outlined
      color="cassiopee-purple"
      height="50px"
      width="188px"
      @click="emitClick"
    >
      <span class="text-button"> Suivant </span>
      <v-icon 
        x-small 
        right
      >
        $arrowRight
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "FormButtonNextStep",
  props: {
    fullColor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>
