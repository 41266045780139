<template>
  <div class="d-flex step-container">
    <v-icon 
      class="mr-3 my-auto"
      size="57px"
      v-text="icon"
    />
    <div class="d-flex flex-column justify-center">
      <div class="d-flex mb-1">
        <span 
          class="white--text text-subtitle-1 mr-1"
          :class="{'step-footer': stepFooter}"
        >
          Etape {{ grade }}
        </span>
        <span
          class="white--text text-subtitle-1"
          :class="{'cassiopee-purple--text': stepFooter}"
        > / 0{{ totalGrade }} </span>
      </div>
      <span 
        class="white--text text-h2"
        :class="{'cassiopee-purple--text': stepFooter}"
      >
        {{ name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFormStepper",
  props: {
    icon: {
      type: String,
      required: true
    },
    grade: {
      type: String,
      required: true
    },
    totalGrade: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    stepFooter: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.step-container {
  width: 200px;
}

.step-footer {
  background: map-deep-get($colors, 'cassiopee-purple', 'base');
  width: 75px;
  height: 23px;
  text-align: center;
  text-transform: uppercase;
}
</style>