<template>
  <div class="cass-layout-default">
    <v-row no-gutters>
      <v-col cols="12">
        <the-form-header />
        <form-stepper-global />
      </v-col>
    </v-row>
    <v-row 
      no-gutters 
      class="cassiopee-grey lighten-1"
    >
      <v-col cols="3">
        <form-stepper-vertical class="filsgood" />
      </v-col>
      <v-col cols="9">
        <v-container class="container-principal">
          <slot />
        </v-container>
      </v-col>
    </v-row>
    <v-row 
      no-gutters 
      class="footer-display-bottom-navigation"
    >
      <v-col cols="12">
        <the-form-footer />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TheFormHeader from '@/components/TheFormHeader.vue'
import FormStepperGlobal from '@/components/FormStepperGlobal.vue'
import FormStepperVertical from '@/components/FormStepperVertical.vue'
import TheFormFooter from '@/components/TheFormFooter.vue'
import { mapActions } from 'vuex'

export default {
  name: 'LayoutDefault',
  components: {
    TheFormHeader,
    FormStepperGlobal,
    FormStepperVertical,
    TheFormFooter,
  },
  created() {

  },
  methods: mapActions('form', ['fetchFormAnswers']),
}
</script>

<style lang="scss" scoped>


.footer-display-bottom-navigation {
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  background: white;
  z-index: 10;
}
.cass-layout-default {
  padding-bottom: 131px;
  box-sizing: border-box;
}
</style>