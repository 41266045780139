<template>
  <div class="text-center">
    <v-btn
      v-if="isFullColor"
      class="ma-2 form-button-save"
      color="cassiopee-purple"
      @click="emitClick()"
    >
      <span class="white--text text-button">Sauvegarder</span>
      <v-icon right>
        $saveWhite
      </v-icon>
    </v-btn>

    <v-btn
      v-else
      class="ma-2"
      outlined
      color="cassiopee-purple"
      height="50px"
      width="188px"
      @click="emitClick"
    >
      <span class="text-button"> Sauvegarder </span>
      <v-icon right>
        $savePurple
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "FormButtonSave",
  props: {
    isFullColor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>
