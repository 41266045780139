<template>
  <div class="form-stepper-vertical pt-11">
    <v-navigation-drawer 
      permanent 
      class="pt-3 pr-16 mx-auto mr-0 "
      color="transparent"
      width="300"
    >
      <v-list 
        nav 
        class="fil-vertical"
      >
        <v-list-item
          v-for="(verticalStep) in currentStep.verticalSteps"
          :key="verticalStep.id"
          :to="`/${verticalStep.path}`"
          class="mb-6"
          active-class="active-item-custom"
          color="cassiopee-purple"
        >
          <v-list-item-content class="d-flex justify-start">
            <span class="text-capitalize text-h3 cassiopee-grey--text">
              {{ verticalStep.name }}
            </span>
          </v-list-item-content>
          <v-list-item-icon
            class="d-flex align-center"
          >
            <v-avatar
              right 
              color="cassiopee-grey"
            >
              <span class="white--text">
                {{ verticalStep.stepItem }}
              </span>
            </v-avatar>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "FormStepperVertical",
  computed: mapGetters('stepper', ['currentStep']),
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';


.v-list-item {
  min-height: 30px !important;
}
</style>

<style lang="scss">

.v-list-item__icon {
  align-self: center !important;
}
.active-item-custom {
  background-color: map-deep-get($colors, 'cassiopee-grey', 'lighten-1');
  > .v-list-item__content {
    > span {
      color: map-deep-get($colors, 'cassiopee-purple', 'base') !important;
    }
  }

  > .v-list-item__icon {
    align-self: center;
    > .v-avatar {
      background-color: map-deep-get($colors, 'cassiopee-purple', 'base') !important;
    }
  }
}
.fil-vertical {
  &:before {
    width: 1px;
    background: rgba($color: #000000, $alpha: 0.1);
    position: absolute;
    top:50px;
    bottom: 50px;
    right: 103px;
    content:" ";
  }
}
</style>