<template>
  <div class="text-center">
    <v-btn
      class="ma-2"
      color="cassiopee-green"
      height="50px"
      width="280px"
      to="/results"
      :disabled="formIncomplete"
    >
      <span class="white--text text-button">
        Voir les résultats
      </span>
      <v-icon right>
        $resultWhite
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "FormButtonResults",
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: mapGetters('form', ['formIncomplete']),
};
</script>

<style lang="scss" scoped>
.btn-disable{
  opacity: 0.5;
  pointer-events: none;
}
</style>
